<script>
import appConfig from '@src/app.config'
import Layout from '@layouts/main'
// import PageHeader from '@components/page-header'
import axios from '@/axios'
import Tablevideo from '@components/video/tablevideo'

/**
 * Contents component
 */
export default {
  page: {
    title: 'Training Basic',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    Layout,

    Tablevideo,

    //  PageHeader
  },
  data() {
    return {
      category_code: 'M001',
      title: 'Training Basic',
    }
  },
  mounted() {},
  methods: {},
}
</script>

<template>
  <Layout>
    <div class="row page-title align-items-center">
      <div class="col-md-3 col-xl-6">
        <h4 class="mb-1 mt-0">Category Muaythai Training Basic</h4>
      </div>
      <div class="col-xl-6">
        <router-link to="/category/trainingbasic/create">
          <button class="btn btn-primary rounded-pill width-lg float-right aligncenter">
            <feather type="file-plus"></feather>Create
          </button>
        </router-link>
        <router-link :to="`/category/cover/${this.category_code}`">
          <button class="btn btn-primary rounded-pill width-lg float-right aligncenter ml-2 mr-2">
            <feather type="image"></feather>&nbsp;Cover
          </button>
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <Tablevideo :category_code="this.category_code"></Tablevideo>
        </div>
      </div>
    </div>
  </Layout>
</template>
